import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { FinancialRiskRole } from 'src/shared/models/user-roles.model';
import { UserService } from 'src/shared/user.service';

@Injectable({
  providedIn: 'root',
})
export class FinancialRiskRolesService {
  readonly rights$ = this.userService.user$.pipe(
    map(({ FrUserRoles }) => FrUserRoles ?? []),
    shareReplay(1),
  );

  constructor(private readonly userService: UserService) {}

  hasRole(role: FinancialRiskRole, companyId: number | null = null): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights.some(right => {    
        if (role === FinancialRiskRole.FinRiskReview) {
          return right.RoleDefinitionId === role;
        }

        return right.RoleDefinitionId === role && right.CompanyId === companyId;
      })),
    );
  }
}
