export enum PpmRole {
  ApproveSDI = 1,
  ApproveRisk = 2,
  ApproveManager = 3,
  ApproveFinance = 4,
  ApproveHSES = 5,
  ApproveCompliance = 6,
  ApproveLegal = 7,
  Read = 8,
  Review = 9,
  Edit = 10,
}

export enum SdiRole {
  Read = 11,
  Edit = 12,
  Review = 13,
}

export enum HsesRole {
  Read = 14,
  Review = 15,
  Edit = 16,
}

export enum TaRole {
  Read = 17,
  Edit = 18,
  EditExternal = 19,
  FundingInstrumentRead = 20,
  FundingInstrumentEdit = 21,
}
