import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class CommonPrivilegesModuleService {
  readonly isAdminEnabled$ = this.userService.user$.pipe(
    map(user => user.IsAdmin || user.IsSuperAdmin),
    shareReplay(1),
  );

  readonly isDataAdminEnabled$ = this.userService.user$.pipe(
    map(user => {
      const superPrivilegeUsers = [
        'mpo@spyro-soft.com',
        'kwl@spyro-soft.com',
        'anna.palosz@unravel.cc',
        'aew@spyro-soft.com',
        'aka@spyro-soft.com',
      ];

      return superPrivilegeUsers.includes(user.UserEmail);
    }),
  );

  constructor(private readonly userService: UserService) {}
}
