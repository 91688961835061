import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { TaRole } from 'src/shared/models/user-roles.model';
import { UserService } from 'src/shared/user.service';

@Injectable({
  providedIn: 'root',
})
export class TaRolesService {
  readonly rights$ = this.userService.user$.pipe(
    map(({ TaUserRoles }) => TaUserRoles ?? []),
    shareReplay(1),
  );

  constructor(private readonly userService: UserService) {}

  hasRole(role: TaRole): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights?.some(right => right.RoleDefinitionId === role)),
    );
  }
}
