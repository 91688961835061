import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { TaRole } from 'src/shared/models/user-roles.model';
import { TaRolesService } from './ta-roles.service';

@Injectable({
  providedIn: 'root',
})
export class TaPrivilegesModuleService {
  readonly isTAEnabled$ = this.taRolesService.rights$.pipe(
    map(rights => rights.length > 0),
  );

  readonly isTACommitmentsEnabled$ = this.taRolesService.rights$.pipe(
    map(rights => {
      const hasRightsForCommitmentModule = rights.some(
        right =>
          right.RoleDefinitionId === TaRole.Read ||
          right.RoleDefinitionId === TaRole.Edit ||
          right.RoleDefinitionId === TaRole.EditExternal,
      );

      return hasRightsForCommitmentModule;
    }),
  );

  readonly isTAFundingInstrumentsEnabled$ = this.taRolesService.rights$.pipe(
    map(rights => {
      const hasRightsForFundingInstrumentsModule = rights.some(
        right =>
          right.RoleDefinitionId === TaRole.FundingInstrumentRead ||
          right.RoleDefinitionId === TaRole.FundingInstrumentEdit,
      );

      return hasRightsForFundingInstrumentsModule;
    }),
  );

  constructor(private taRolesService: TaRolesService) {}
}
