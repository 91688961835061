import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { FinancialRiskRolesService } from './financial-risk-roles.service';

@Injectable({
  providedIn: 'root',
})
export class FinancialRiskPrivilegesModuleService {
  readonly isFinancialRiskEnabled$ = this.financialRiskRolesService.rights$.pipe(
    map(rights => rights.length > 0),
  );

  constructor(private financialRiskRolesService: FinancialRiskRolesService) {}
}
