export default {
AAD_CLIENT_ID: '4f25dd1d-e9f4-414e-8d37-c6ff2ff28abd',
AAD_TENANT_ID: '61d2c43b-be5c-4d7e-8cfe-5bb8b38c5873',
API_CLIENT_ID: '83835634-b292-43f3-ad42-4099aff3bba8',
API_COMMON: 'https://app-webforms-compass-dev.azurewebsites.net/Common',
API_SDI: 'https://app-webforms-compass-dev.azurewebsites.net/Sdi',
API_PPM: 'https://app-webforms-compass-dev.azurewebsites.net/Ppm',
API_HSES: 'https://app-webforms-compass-dev.azurewebsites.net/Hses',
API_TA: 'https://app-webforms-compass-dev.azurewebsites.net/Ta',
API_FR: 'https://app-webforms-compass-dev.azurewebsites.net/Fr',
API_DATA_ADMIN: 'https://app-webforms-compass-dev.azurewebsites.net/DataAdministration',
API: 'https://app-webforms-compass-dev.azurewebsites.net/'
};
