import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HsesRole } from 'src/shared/models/user-roles.model';
import { UserService } from 'src/shared/user.service';

@Injectable({
  providedIn: 'root',
})
export class HsesRolesService {
  readonly rights$ = this.userService.user$.pipe(
    map(({ HsesUserRoles }) => HsesUserRoles ?? []),
    shareReplay(1),
  );

  constructor(private readonly userService: UserService) {}

  hasRole(role: HsesRole, companyId: number | null = null): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights?.some(right =>
        right.CompanyId === companyId && right.RoleDefinitionId === role,
      )),
    );
  }
}
