import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MonoTypeOperatorFunction, map, pipe } from 'rxjs';
import { components } from 'src/api-schema';
import { DateAggregationType, scpiFields } from './utils-models';
import { Scpi } from './services/api/common-api.interface';

export function compareJSON<T>(newValue: T, oldValue: T): boolean {
  // both values are the same -> returns true as there is no change
  if (newValue === oldValue) {
    return true;
  }

  // checks if at least one value is null then we know that there was at least one change
  if (newValue == null || oldValue == null) {
    return false;
  }

  if (newValue instanceof Date && oldValue instanceof Date) {
    return newValue.getTime() === oldValue.getTime();
  }

  if (Array.isArray(newValue) && Array.isArray(oldValue)) {
    return newValue.length === oldValue.length && newValue.every((item, index) => compareJSON(item, oldValue[index]));
  }

  if (typeof newValue === 'object' && typeof oldValue === 'object') {
    const keys = Object.keys(newValue) as (keyof T)[];
    return keys.length === Object.keys(oldValue).length && keys.every((key) => compareJSON(newValue[key], oldValue[key]));
  }

  return false;
}

export class NamedFormGroup<TControl extends {
  [K in keyof TControl]: AbstractControl;
} = object> extends FormGroup<TControl> {
  constructor(public name: string, ...controls: ConstructorParameters<typeof FormGroup<TControl>>) {
    super(...controls);
  }
}

interface CommentCount {
  Items?: components['schemas']['Api.Common.ViewModels.CommentItem'][] | null;
}

export function createCommentCountFormControl<T extends object>(
  data: CommentCount | undefined,
): FormControl<{ Items: { Count: number, FieldName: keyof T }[] } | null> {
  if (!data) {
    return new FormControl(null);
  }

  return new FormControl({
    Items: data.Items?.map(({ Count, FieldName }) => ({
      Count: Count ?? 0,
      FieldName: (FieldName ?? '') as keyof T,
    })) ?? [],
  });
}

export function trackByFormArrayItemId(index: number, item: FormGroup<{ Id: FormControl<number | null> }>): number | null {
  return item.controls.Id.value;
}

export interface Diff<T = unknown, U = T> {
  oldValue: U;
  newValue: T;
  type: 'ADDED' | 'MODIFIED' | 'REMOVED';
}

export interface ArrayItem {
  Id: number;

  [key: string]: unknown;
}

export function joinIds(items: Array<ArrayItem>): string {
  return items.map(({ Id }) => Id)
    .join(',');
}

export function processAddedDynamicValues(fieldName: string, { newValue }: Diff<ArrayItem>): [ FieldName: string, Value: ArrayItem ][] {
  return [
    [
      fieldName,
      Object.fromEntries(
        Object
          .entries(newValue)
          .map(([ key, value ]) => {
            return [ key, value instanceof Date ? `${value ? value.valueOf() / 1000 : 0}` : value ];
          })
          .filter(([ key, value ]) => key !== 'Id' || value !== null),
      ) as ArrayItem,
    ],
  ];
}

export interface FieldDefinition {
  FieldName: string;
  FieldValue: string;
  CollectionMemberId?: number;
}

export function processModifiedDynamicValues(
  fieldName: string,
  { oldValue, newValue }: Diff<ArrayItem>,
): [ FieldName: string, Value: FieldDefinition ][] {
  return Object
    .entries(newValue)
    .filter(([ key, value ]) => (oldValue)[key] !== value)
    .map(([ FieldName, FieldValue ]) => [
      fieldName,
      {
        FieldName,
        FieldValue: String(FieldValue ?? ''),
        CollectionMemberId: newValue.Id,
      },
    ]);
}

export function processRemovedDynamicValues(fieldName: string, { oldValue }: Diff<ArrayItem>): [ FieldName: string, Value: string ][] {
  return [ [ fieldName, String(oldValue.Id) ] ];
}

export function groupDynamicValuesChanges<T>(
  dynamicDataApi: Record<string, string> | undefined,
  dynamicValuesChanged: Array<[ FieldName: string, FieldValue: T ]>,
) {
  return Object.keys(dynamicDataApi ?? {})
    .map(key => [
      key,
      dynamicValuesChanged
        .filter(([ FieldName ]) => FieldName.startsWith(`${key}.#`))
        .map(([ , value ]) => value),
    ] as [ key: string, changes: T[] ]);
}

export function processBaseValue(fieldName: string, { newValue: value }: Diff): FieldDefinition {
  return {
    FieldName: fieldName,
    FieldValue: String(value ?? ''),
  };
}

export function convertToDate(date: string | number): Date {
  return new Date(typeof date === 'string' ? date : date * 1000);
}

/**
 * Returns string in the format of DD/MM/YYYY | MM/YYYY | YYYY converted from Date UTC in seconds
 */
export function convertToDateStaticString(
  date: number | null | undefined,
  aggregationType?: DateAggregationType,
): string | null {
  if (!date) {
    return null;
  }

  const tempDate = new Date(date * 1000);
  const day = tempDate.getUTCDate() < 10 ? `0${tempDate.getUTCDate()}` : tempDate.getUTCDate();
  const month = tempDate.getUTCMonth() < 9 ? `0${tempDate.getUTCMonth() + 1}` : tempDate.getUTCMonth() + 1;
  const year = tempDate.getUTCFullYear();

  switch (aggregationType) {
    case DateAggregationType.DAY:
      return `${day}/${month}/${year}`;
    case DateAggregationType.MONTH:
      return `${month}/${year}`;
    case DateAggregationType.YEAR:
      return `${year}`;
    default:
      return `${day}/${month}/${year}`;
  }
}

export function convertDateStaticStringToDateObject(date: string): Date {
  const [day, month, year] = date.split('/').map(Number);

  return new Date(year, month - 1, day);
}

export function convertSecondsToMilliseconds(timestampDate: number | null | undefined): number {
  return Number(timestampDate ?? 0) * 1000;
}

export const isScpiField = (key: keyof Scpi | string) => {
  return scpiFields.includes(key);
};

export function disableScpiFields<T extends FormGroup>(): MonoTypeOperatorFunction<T> {
  return pipe(
    map(formGroup => {
      scpiFields
        .filter(field => formGroup.controls[field])
        .forEach(fieldName => {
          formGroup.controls[fieldName].disable();
        });

      return formGroup;
    }),
  );
}

export const numberSizeThreshold = 2147483647; // max INT capacity in C#
export const numberDecimalRegExp = /^-?\d+(\.\d*)?$/;
export const numberIntegerRegExp = /^[0-9]+$/;

/**
 * Converts string value to a number.
 * Returns 0 when conversion is not possible.
 */
export const convertToNumber = (value: unknown) => {
  if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string') {
    const num = parseFloat(value);

    return Number.isNaN(num) ? 0 : num;
  }

  return 0;
};
