import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, zip } from 'rxjs';
import { FinancialRiskPrivilegesModuleService } from '../../financial-risk/services/financial-risk-privileges-module.service';
import { HsesPrivilegesModuleService } from '../../hses/services/hses-privileges-module.service';
import { PpmPrivilegesModuleService } from '../../ppm/services/ppm-privileges-module.service';
import { SdiPrivilegesModuleService } from '../../sdi/services/sdi-privileges-module.service';
import { CommonPrivilegesModuleService } from '../../shared/services/common-privileges-module.service';
import { TaPrivilegesModuleService } from '../../ta/shared/services/ta-privileges-module.service';

@Component({
  selector: 'pidg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private readonly isPPMEnabled$ = this.ppmPrivilegesModuleService.isPPMEnabled$;
  private readonly isSDIEnabled$ = this.sdiPrivilegesModuleService.isSDIEnabled$;
  private readonly isHSESEnabled$ = this.hsesPrivilegesModuleService.isHSESEnabled$;
  private readonly isTAEnabled$ = this.taPrivilegesModuleService.isTAEnabled$;
  private readonly isAdminEnabled$ = this.commonPrivilegesModuleService.isAdminEnabled$;
  private readonly isFinancialRiskEnabled$ = this.financialRiskPrivilegesModuleService.isFinancialRiskEnabled$; // TODO change
  private readonly subscription = new Subscription();

  noAccess = false;

  constructor(
    private readonly ppmPrivilegesModuleService: PpmPrivilegesModuleService,
    private readonly sdiPrivilegesModuleService: SdiPrivilegesModuleService,
    private readonly hsesPrivilegesModuleService: HsesPrivilegesModuleService,
    private readonly taPrivilegesModuleService: TaPrivilegesModuleService,
    private readonly financialRiskPrivilegesModuleService: FinancialRiskPrivilegesModuleService,
    private readonly commonPrivilegesModuleService: CommonPrivilegesModuleService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      zip([
        this.isSDIEnabled$,
        this.isPPMEnabled$,
        this.isHSESEnabled$,
        this.isTAEnabled$,
        this.isFinancialRiskEnabled$,
        this.isAdminEnabled$,
      ]).subscribe({
        next: ([
          sdiEnabled,
          ppmEnabled,
          hsesEnabled,
          taEnabled,
          isFinancialRiskEnabled,
          adminEnabled,
        ]) => {
          this.loading$.next(false);

          if (ppmEnabled) {
            this.loading$.next(false);
            void this.router.navigate(['projects', 'ppm']);
          } else if (sdiEnabled) {
            this.loading$.next(false);
            void this.router.navigate(['projects', 'sdi']);
          } else if (hsesEnabled) {
            this.loading$.next(false);
            void this.router.navigate(['projects', 'hses']);
          } else if (taEnabled) {
            this.loading$.next(false);
            void this.router.navigate(['ta']);
          } else if (isFinancialRiskEnabled) {
            void this.router.navigate(['financial-risk']);
          } else if (adminEnabled) {
            this.loading$.next(false);
            void this.router.navigate(['admin']);
          } else {
            this.loading$.next(false);
            this.noAccess = true;
          }
        },
        error: () => {
          this.loading$.next(false);
          this.noAccess = true;
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
