import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { Mapper, ProjectLockStatus } from '../../common-types';
import { ModuleType } from '../models/module-type.model';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  readonly activeRoute$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(null),
    map(() => {
      let route = this.router.routerState.root;

      while (route.firstChild) {
        route = route.firstChild;
      }

      return route;
    }),
    shareReplay(1),
  );

  readonly activeRouteData$ = this.activeRoute$.pipe(
    switchMap(route => combineLatest(route.pathFromRoot.map(({ data }) => data))),
    map(paths => paths.reduce((acc, data) => ({ ...acc, ...data }), {})),
    shareReplay(1),
  );

  readonly activeProjectId$ = this.activeRoute$.pipe(
    switchMap(route => combineLatest(route.pathFromRoot.map(({ params }) => params))),
    map(paths => paths.reduce((acc, params) => ({ ...acc, ...params }), {})),
    map(params => params[ 'id' ] as string),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly activeUserId$ = this.activeRoute$.pipe(
    switchMap(route => combineLatest(route.pathFromRoot.map(({ params }) => params))),
    map(paths => paths.reduce((acc, params) => ({ ...acc, ...params }), {})),
    map(params => params[ 'userId' ] as string),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly moduleType$: Observable<ModuleType> = this.activeRoute$.pipe(
    map(() => {
      const currentUrl = this.router.url;
      if (currentUrl.includes('sdi')) return ModuleType.SDI;
      else if (currentUrl.includes('ppm')) return ModuleType.PPM;
      else if (currentUrl.includes('hses')) return ModuleType.HSES;
      else if (currentUrl.includes('ta/commitments')) return ModuleType.TA_COMMITMENTS;
      else if (currentUrl.includes('ta/funding-instruments')) return ModuleType.TA_FUNDING_INSTRUMENTS;
      else if (currentUrl.includes('financial-risk')) return ModuleType.FINANCIAL_RISK;
      else if (currentUrl.includes('data-admin')) return ModuleType.DATA_ADMIN;
      else return ModuleType.ADMIN;
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isPpmModule$ = this.moduleType$.pipe(
    map(moduleType => moduleType === ModuleType.PPM),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isSdiModule$ = this.moduleType$.pipe(
    map(moduleType => moduleType === ModuleType.SDI),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isHsesModule$ = this.moduleType$.pipe(
    map(moduleType => moduleType === ModuleType.HSES),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isTaCommitmentsModule$ = this.moduleType$.pipe(
    map(moduleType => moduleType === ModuleType.TA_COMMITMENTS),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isTaFundingInstrumentsModule$ = this.moduleType$.pipe(
    map(moduleType => moduleType === ModuleType.TA_FUNDING_INSTRUMENTS),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isFinancialRiskModule$ = this.moduleType$.pipe(
    map(moduleType => moduleType === ModuleType.FINANCIAL_RISK),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectNew$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'new');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectView$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'view');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectEdit$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'edit');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectApproval$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'approval');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectReview$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'review');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly activeTab$: Observable<string> = this.activeRouteData$.pipe(
    map(data => data['tabName'] as string),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly mapper$ = this.activeRouteData$.pipe(
    map(data => data[ 'mapper' ] as Mapper),
    shareReplay(1),
  );

  readonly dynamicDataApi$ = this.activeRouteData$.pipe(
    map(data => (data[ 'dynamicDataApi' ] as Record<string, string> | undefined)),
    shareReplay(1),
  );

  readonly multiselectDictionaries$ = this.activeRouteData$.pipe(
    map(data => (data[ 'multiselectDictionaries' ] as string[] | undefined)),
    shareReplay(1),
  );

  readonly projectLocked$ = this.activeRouteData$.pipe(
    map(data => {
      const projectLockedStatus = data['isProjectLocked'] as ProjectLockStatus;

      return {
        IsLocked: projectLockedStatus?.IsLocked ?? false,
        UserName: projectLockedStatus?.UserName ?? '',
      };
    }),
    shareReplay(1),
  );

  constructor(private readonly router: Router) {}
}
