import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AadUserService {
  constructor(private authService: MsalService) {}

  logout(): void {
    this.authService.logoutRedirect();
  }
}
