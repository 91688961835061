import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PidgApp } from './pidg-app.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([
      {
        path: '',
        component: PidgApp,
        canActivateChild: [MsalGuard],
        children: [
          {
            path: '',
            component: HomeComponent,
          },
          {
            path: 'projects/sdi',
            loadChildren: () => import('../sdi/sdi.module').then(m => m.SdiModule),
          },
          {
            path: 'projects/ppm',
            loadChildren: () => import('../ppm/ppm.module').then(m => m.PpmModule),
          },
          {
            path: 'projects/hses',
            loadChildren: () => import('../hses/hses.module').then(m => m.HsesModule),
          },
          {
            path: 'admin',
            loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule),
          },
          {
            path: 'data-admin',
            loadChildren: () => import('../data-admin/data-admin.module').then(m => m.DataAdminModule),
          },
          {
            path: 'ta',
            loadChildren: () => import('../ta/ta.module').then(m => m.TaModule),
          },
        ],
      },
      {
        path: '**',
        redirectTo: '',
      },
    ], {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
