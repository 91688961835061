import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class CommonPrivilegesModuleService {
  readonly isAdminEnabled$ = this.userService.user$.pipe(
    map(user => user.IsAdmin || user.IsSuperAdmin),
    shareReplay(1),
  );

  readonly isDataAdminEnabled$ = this.userService.user$.pipe(
    map(({ FrUserRoles }) => FrUserRoles.length > 0),
    shareReplay(1),
  );

  constructor(private readonly userService: UserService) {}
}
