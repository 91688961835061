import { Injectable } from '@angular/core';
import { map, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { CommonApiService } from './services/api/common-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly refetch$ = new Subject<void>();
  readonly refetchAllUsers$ = new Subject<void>();

  readonly user$ = this.refetch$.pipe(
    startWith({}),
    switchMap(() => this.commonApiService.getCurrentUser()),
    shareReplay(1),
  );

  readonly userMail$ = this.user$.pipe(
    map(user => user.UserEmail),
  );

  readonly userName$ = this.user$.pipe(
    map(user => user.UserName),
  );

  readonly allUsers$ = this.refetchAllUsers$.pipe(
    startWith(void {}),
    switchMap(() => this.commonApiService.getUsers()),
    shareReplay(1),
  );

  constructor(private readonly commonApiService: CommonApiService) {}

  refetch() {
    this.refetch$.next(void {});
  }

  refetchAllUsers() {
    this.refetchAllUsers$.next(void {});
  }

  exportUsersToExcel(userIds: number[]) {
    return this.commonApiService.exportUsersToExcel(userIds);
  }
}
