import { Scpi } from './services/api/common-api.interface';

export interface TabDetails {
  path: string;
  tabName: string;
  title: string;
  icon: string;
  dynamicDataApi?: Record<string, string>;
}

export interface ProjectsListItem {
  Name?: string | null;
  CompanyId?: number | null;
  PIDGCommon?: string | null;
  Id?: number | null;
  StepId?: number;
  ProjectCountryId?: number | null;
  SectorId?: number | null;
  IsLocked?: boolean;
  IsLockedByUser?: string | null;
  IsLockedSince?: number | null;
  ProjectNumber?: number | null;
  StageId?: number | null;
}

export enum DateAggregationType {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export const scpiFields: Array<keyof Scpi | string> = [
  'Name',
  'CompanyId',
  'PIDGCommon',
  'SectorId',
  'SubSectorId',
  'ProjectCountryId',
  'DateOfSigning',
  'DateOfFinancialClose',
  'DateOfActualConstructionCompletion',
  'TypeOfInterventionId',
  'ConnectionTypeId',
];
